import React from 'react';
import BackVideo from '../assets/about/backgroundmb.webm'
import PosterVideo from '../assets/about/postervid.jpg'

const VideoBackground = () => {
  return (
    <div className="video-background"  poster={PosterVideo}>
      <video autoPlay muted loop playsInline>
        <source src={BackVideo} type="video/webm" />
        {/* Agrega más sources para otros formatos de video (webm, ogg) si es necesario */}
      </video>
    </div>
  );
};

export default VideoBackground;