import React, { Component } from 'react'
import './App.css'
import { InitialState } from './util/consts';
import NavWeb from './componens/NavWeb';
import Home from './componens/Home';
import VideoBackground from './componens/VideoBackground';
import Modalx from '../src/componens/Modals/Modal'



export default class App extends Component {

  constructor(props) {
    super(props);
      this.state = InitialState.App
      this.nowConnect = false    
  } 


 /*<Image src={BackgrondCity} width="100%"  className=' z-w10h10 '/>*/
  render() {
    return (
      <>
      <VideoBackground />
      <NavWeb/> 
         
        <div className='back-50-res w-100  ' >
          
         <Home /> 
          
   
         </div>
        
         <Modalx/> 
      </>     
    )
  }
}


