import React, { useState, useEffect } from 'react';
import {Card,InputGroup,FormControl,Button, Row, Col,Image, ModalFooter } from 'react-bootstrap'
import {LiaStoreAltSolid} from 'react-icons/lia'
import { Modal, message } from 'antd';
import {RiTokenSwapFill} from 'react-icons/ri'
import {FaBook} from 'react-icons/fa'
import metaMaskIco from './metamask.png'

const Modalx = ({ x, y, width, height, imageUrl }) => {
  const [showImages, setShowImages] = useState(false);
  const [whats,setWhats] = useState('')
  const [names,setName] = useState('')
  const [pedi,setPedi] = useState('')
  const [showNextModal,setShowNextModal] = useState(false)
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImages(true);
    }, 5000); // Mostrar la imagen después de 10 segundos
    return () => clearTimeout(timeout); // Limpiar el temporizador al desmontar el componente
  }, []);
  const spaceToWork = ()=>{
      setShowNextModal(true)
    }

  const closeModalLogin = ()=>{
      setShowNextModal(false)
    }

   const handleOpenAbout =( )=>{
      alert('Hecha por Daniel Alejandro Barajas Tovar 8446556810')
    }

  return (
   <div  className='btn-login' >
     {showImages && (
      <Button className='intro-btn size-16' onClick={()=>spaceToWork()} style={{zIndex: '350',textAlign:'center', background: '#004743',margin:'0',width:'auto'}}>
       Entrar </Button>  
        
      )}
      <Modal
        title=""
        centered
        open={showNextModal}
        onOk={() => closeModalLogin()}
        onCancel={() => closeModalLogin ()}
      >
       <Row>
          <Col>
          <LiaStoreAltSolid  onClick={()=>{message.info('En construccion',4000)}}  className='z-150 icon-marketplace c-pointer' />
          <p style={{fontSize:'10.5px',placeSelf:'center',paddingTop:'30px'}}>Marketplace</p>
          </Col>
          <Col>
          <Image style={{margin:'5.5%'}} onClick={()=>{window.location='/perfil'}} id='logo-metamask' src={metaMaskIco} className=' z-150 c-pointer' />
             <br/><p style={{fontSize:'10.5px',placeSelf:'center'}}> Ingresar con Metamask</p>   
          </Col>
       </Row>
       <Row>
          <Col>
          <RiTokenSwapFill onClick={()=>{window.location='/swapcoins'}}  className='z-150 icon-marketplace c-pointer' />
          <p style={{fontSize:'10.5px',placeSelf:'center',paddingTop:'30px'}}>Intercambio</p>
          </Col>
          <Col>
          <FaBook  className='z-150 icon-marketplace c-pointer' onClick={()=>{window.location='https://drive.google.com/file/d/16gZ66yn2VhtjbDGh9aK13eEvbdxZXK1g/view?'}} id='logo-metamask' />
            <p style={{fontSize:'10.5px',placeSelf:'center',paddingTop:'30px'}}> Bluepaper</p>   
          </Col>
       </Row>
       
      </Modal>
   </div>
 
  );
};

export default Modalx;
