import React, { useEffect, useState} from 'react'
import {Navbar, Container, Nav}  from 'react-bootstrap'
import MBlogo from '../assets/mb.png'
import { Image } from 'antd'

export default function NavWeb() {

    window.onscroll = ()=>{
      let noNav  = window.pageYOffset;
 
      if(noNav<=300){
        console.log('quek')
        let displayA = document.querySelectorAll('.navbar')
        displayA[0].classList.remove('on-display')
        displayA[0].classList.add('no-display')
        displayA[0].classList.remove('d-n')
      }else{
        console.log('kueq')
        let displayB = document.querySelectorAll('.navbar')
        displayB[0].classList.add('on-display')
        displayB[0].classList.remove('no-display')
        displayB[0].classList.remove('d-n')        
      }
    }


  return (
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark" id='nav' className='d-n' >
      <Container>      
      <Navbar.Brand href="#home"><Image src={MBlogo}></Image></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav >
            <Nav.Link eventKey={1} href="#Whitepp">BluePaper</Nav.Link>
            <Nav.Link eventKey={2} href="#Bikes">Baiks</Nav.Link>
            <Nav.Link eventKey={3} href="#Bikers">Baiker</Nav.Link>
            <Nav.Link eventKey={4} href="#wallet"></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
