import { Button ,InputGroup,FormControl,Card,Row,Col} from "react-bootstrap"
import React, { Component } from 'react'
import { InitialState } from "../util/consts"
import { Modal } from "antd"

export default class NumberGame extends Component {
  
  constructor(props){
    super(props)
    this.state = InitialState.rifaMaker
  }
  
 async viewModalNow(){
    this.state.showNextModal = true
  }

  spaceToWork(){
    console.log('sample')
    this.state.showNextModal = true
    console.log(this.state.showNextModal)
    this.state.viewModalNow()
  }

 

  closeModalLogin (){
    this.state.showNextModal = false
  }

  render() {
    return (  
      <>
      <Card>
        <Card.Header style={{color:'black'}}>Rifa entre amigos</Card.Header>
        <Card.Body>
          <Row>
            <Col>
             {Array.from({ length: 10 }, (_, i) => i + 1).map((number, index) => (
                
                <div key={number} className={`number-box ${number ? 'selected' : ''}`}  >
                     <Button onClick={()=>{this.spaceToWork()}}  style={{margin:'2%',width:'100%'}}>  {number} </Button>                    
                </div>
                             
              ))}   
            </Col>
          </Row>
          <br/>
        {this.state.winner !== null && <p>El ganador es: {this.state.winner}</p>}
        </Card.Body>
      </Card>

      <Modal
        title=""
        centered
        open={this.state.showNextModal}
        onOk={() => this.closeModalLogin()}
        onCancel={() => this.closeModalLogin()}
      >
        <InputGroup>
                <FormControl
                  placeholder='Nombre'
                  aria-label='namePlayer'
                  type='text'
                  onChange={(e)=>{this.state.nombreItem=e.target.value}}
                />
              </InputGroup>
              <InputGroup>
                <FormControl
                  placeholder=''
                  aria-label='nameItem'
                  type='text'
                  onChange={(e)=>{this.state.nombreItem=e.target.value}}
                />
              </InputGroup>      
      </Modal>
      </>    
    )
  }
}
 




  




  

