import React from 'react'

import { Result, Button } from 'antd';



function Error404() {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Lo sentimos, esta página no existe"
            extra={<a href="/">
                <Button danger type="danger">Atras</Button></a>}

        />
    )
}

export default Error404