import {
    UserOutlined
} from '@ant-design/icons'
import { collection,getDocs,getDoc,setDoc,doc,arrayUnion,updateDoc } from "firebase/firestore";
import db from '../firebase'

export const Networks = {
    bsct: {
        id: 97,
        rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
    }
}

const  connect   = async() => {  
    
   const metaDataUser = doc(db,'_address_web3',this._web3Instance.account)
   const getUserData = await getDoc(metaDataUser)  
   }

export  const loadResurses = ()  => { 
   

}



export const InitialState = {
    App: {
        account: undefined,
        displayAccount: undefined,
        balance: undefined
    },
    Account: {
        account: undefined,
        amountItem:undefined,
        articule_box: [],
        balance: undefined,
        name_account: undefined,
        mail_account: undefined,
        balance_usd: 0,
        token_price: undefined,
        token_price_usd: 0,
        token_price_change: 0,
        name_us:undefined,
        data: {},
        isModalOpen: false,
        toUpTxT:'BNB',
        toDwnTxT:'MBT',
        phase: {},
        phases: [],        
        percentPhase: 0,
        isModalVisible: false,
        input_token: 0,
        input_bnb: 0,
        token_symbol: undefined,
        token_key_dapp: undefined,
        token_box_dapp: undefined,       
        storeAccount:false,
        dataLoad:false, 
        img_url: undefined,
    },
    InitialStoreAccount:{
        nameStore:undefined,
        nameM:undefined,
        img_url: undefined,
        contactStore:undefined,
        streetStore:undefined,
        categoryStore:undefined
    },
    NextGameList: {
        account: undefined,
        section: undefined,
        isModalVisible: false,
        data: [],
        itemToBuy: {},
        allowance: 0,
        itemDetailsContact: undefined,
        token_symbol: undefined,
        imagesLoaded: false
    },
    TokenSale: {
        account: undefined,
        phase: {},
        phases: [],
        percentPhase: 0,
        isModalVisible: false,
        input_token: 0,
        input_bnb: 0,
        token_symbol: undefined
    },
    SwapMain:{
        toUpTxT:'BNB',
        toDwnTxT:'MBT',
        account: undefined,
        phase: {},
        phases: [],
        percentPhase: 0,
        isModalVisible: false,
        input_token: 0,
        input_bnb: 0,
        token_symbol: undefined,
        balance: undefined,
        totalBky : undefined,
        headCardSell: 'Comprar con BNB',
        haveItemWithoutBox: false,
        toPorcent : undefined,
    },
    rifaMaker:{
        winner: null,        
        streetStore: undefined,
        selectedNumbers: new Array(10).fill(false),
        userNameToPlay: undefined,
        baucher : undefined,
        idRifaTx: undefined,
        contact: undefined,
        showNextModal: false,
    }

}